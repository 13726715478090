import { styled } from 'stitches.config';
import MeatballIcon from '@assets/icons/meatball.svg';
import CrownIcon from '@assets/icons/crown.svg';
import DefaultProfileIcon from '@assets/icons/avatar.svg';
import { usePlayroomContext } from '..';

// 인터페이스 사용하다가 type을 기본적으로 사용하고 interface가 필요한 경우 또는 성능 상의 이점이 있는 경우만 사용하라는 글을 봐서 함 사용해봤습니다.
type UserItemProps = {
  name: string;
  imageUrl: string;
  isHost: boolean;
  isMe: boolean;
  userId?: string;
  agoraUserId?: number;
};

export default function UserItem(props: UserItemProps) {
  const { push, setCurrentUserData } = usePlayroomContext();

  return (
    <Container>
      <ImageWrapper id={`user-id-${props.agoraUserId}`}>
        <Image src={props.imageUrl || DefaultProfileIcon} alt="유저 이미지" />
        {props.isHost && <CrownImage src={CrownIcon} alt="방장 아이콘" />}
      </ImageWrapper>
      <Username>{props.name}</Username>
      {!props.isMe && (
        <Menu
          onClick={() => {
            push('/playroom/settings');
            setCurrentUserData(props);
          }}
        >
          <img src={MeatballIcon} alt="사용자별 설정 아이콘" />
        </Menu>
      )}
    </Container>
  );
}

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  padding: '12px 16px',
});

const ImageWrapper = styled('div', {
  position: 'relative',
  width: '48px',
  height: '48px',
  marginRight: '16px',
  borderRadius: '50%',
});

const Image = styled('img', {
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  objectFit: 'cover',
});

const CrownImage = styled('img', {
  position: 'absolute',
  top: 0,
  right: '-2px',
});

const Username = styled('div', {
  flexGrow: 1,
  flexShrink: 1,
  marginRight: '16px',
});

const Menu = styled('div', {
  cursor: 'pointer',
});
