export default function ChevronDown() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.46447 4.46447L6 8"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M6 8L9.53553 4.46447"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
}
