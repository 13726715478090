import {
  getLocalStorage,
  getLocalStorageObject,
  removeLocalStorage,
  setLocalStorage,
} from '@src/utils/localStorage';
import axios from 'axios';
import { getMe } from './getMe';
import { decodeJwt } from 'jose';
import { memoizedPostRefreshAccessToken } from './postRefreshAccessToken';
import { CORE_SERVER_HOST } from '.';
import { CLIENT_ID, LOGIN_PAGE_HOST } from '..';
import { authApi } from '../auth';

export function useCoreApiWithAuth() {
  const axiosInstance = axios.create({
    baseURL: `https://${CORE_SERVER_HOST}`,
  });

  axiosInstance.interceptors.request.use(async (config) => {
    try {
      if (!config.headers) return config;

      let token = getLocalStorage('accessToken');
      if (!token) throw new Error('No token');

      const { exp } = decodeJwt(token);
      if (!exp) throw new Error('No exp');

      const now = Math.floor(Date.now() / 1000);
      if (exp < now) {
        try {
          const refreshToken = getLocalStorage('refreshToken');
          if (!refreshToken) throw new Error('No refreshToken');

          const refreshed = await memoizedPostRefreshAccessToken({
            accessToken: token,
            refreshToken,
          });
          setLocalStorage('accessToken', refreshed.token);
          setLocalStorage('refreshToken', refreshed.refreshToken);
          token = refreshed.token;
        } catch (e) {
          // pass
        }
      }

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        // SSO 세션이 있을 수 있으므로 한번 더 시도
        const {
          data: { token: newAccessToken, refreshToken: newRefreshToken },
        } = await authApi.apiAuthReissueGet();

        if (newAccessToken && newRefreshToken) {
          setLocalStorage('accessToken', newAccessToken);
          setLocalStorage('refreshToken', newRefreshToken);
        } else {
          throw new Error('No token or refreshToken');
        }

        config.headers.Authorization = `Bearer ${newAccessToken}`;
      }
    } catch (e) {
      removeLocalStorage('userData');
      throw new Error('No token');
    }

    return config;
  });

  return {
    // 인증이 포함된 API
    getMe: () => getMe(axiosInstance),
  };
}
