import axios from 'axios';
import { API_ENV, CLIENT_ID, INTEGRATE_LOGIN_HOST, LOGIN_PAGE_HOST } from '..';
import { Configuration, APIApi } from '@uniquegood/realworld-play-interface';
import { getLocalStorage, setLocalStorage } from '@src/utils/localStorage';
import { decodeJwt } from 'jose';
import { memoizedPostRefreshAccessToken } from '../core/postRefreshAccessToken';
import { authApi } from '../auth';
import { APP_ENV } from '@src/config';

export const PLAY_HOST = {
  production: 'api.play.realworld.to',
  development: 'api-test.play.realworld.to',
}[API_ENV];

export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: `https://${PLAY_HOST}`,
});
export const axiosInstanceWithoutToken = axios.create({
  baseURL: `https://${PLAY_HOST}`,
});
axiosInstance.interceptors.request.use(async (config) => {
  if (!config.headers) return config;
  try {
    const accessToken = getLocalStorage('accessToken');
    if (!accessToken) throw new Error('No accessToken');

    const { exp } = decodeJwt(accessToken);
    if (!exp) throw new Error('No exp');

    const now = Math.floor(Date.now() / 1000);
    if (exp < now) {
      const refreshToken = getLocalStorage('refreshToken');
      if (!refreshToken) throw new Error('No refreshToken');

      const refreshed = await memoizedPostRefreshAccessToken({
        accessToken,
        refreshToken,
      });
      if (!refreshed.token || !refreshed.refreshToken)
        throw new Error('No refreshed token');

      setLocalStorage('accessToken', refreshed.token);
      setLocalStorage('refreshToken', refreshed.refreshToken);
      config.headers.Authorization = `Bearer ${refreshed.token}`;
    } else {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  } catch (e) {
    try {
      const {
        data: { token: newAccessToken, refreshToken: newRefreshToken },
      } = await authApi.apiAuthReissueGet();

      if (newAccessToken && newRefreshToken) {
        setLocalStorage('accessToken', newAccessToken);
        setLocalStorage('refreshToken', newRefreshToken);
        config.headers.Authorization = `Bearer ${newAccessToken}`;
      }
    } catch (e) {
      setTimeout(() => {
        window.location.href = `https://${LOGIN_PAGE_HOST}/ui/auth/oauth?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${encodeURI(
          window.location.href
        )}`;
      }, 300);
    }
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.status >= 500) {
        alert('알 수 없는 오류가 발생했어요. 잠시 후 다시 시도해주세요.');
      }
      throw error;
    }
  }
);

const configuration = new Configuration({
  basePath: 'https://api-test.play.realworld.to',
});

export const playApi = new APIApi(configuration, undefined, axiosInstance);
export const playApiWithoutToken = new APIApi(
  configuration,
  undefined,
  axiosInstanceWithoutToken
);
