import { AxiosInstance } from 'axios';
import {
  array,
  assert,
  optional,
  string,
  type,
  enums,
  nullable,
} from 'superstruct';

// 어떤 값이 추가될지 몰라 object 대신 type으로 구현
export const getMeResponse = type({
  credentials: array(
    type({
      provider: enums([
        'Self',
        'Facebook',
        'Kakao',
        'Naver',
        'Apple',
        'Anonymous',
      ]),
      providerId: string(),
    })
  ),
  email: optional(string()),
  // emailVerified: boolean(),
  // id: string(),
  // isPlayedBefore: optional(boolean()),
  name: string(),
  // phone: optional(string()),
  // phoneVerified: boolean(),
  profileImage: optional(nullable(string())),
  // role: optional(string()),
  createdAt: string(),
});

export type GetMeResponse = typeof getMeResponse.TYPE;

export async function getMe(axiosInstance: AxiosInstance) {
  const { data } = await axiosInstance.get(`/api/me`);

  assert(data, getMeResponse);
  return data;
}
