import { useAgoraControlContext } from '@src/providers/Agora';
import React from 'react';

// 실제로 사용할 커스텀 훅 영역
export function useAgora() {
  const agoraContextData = useAgoraControlContext();
  const {
    localAudioTrack,
    remoteAudioTracks,
    isMuted,
    isAllMuted,
    setIsMuted,
    setIsAllMuted,
    setUserVolumes,
  } = agoraContextData;

  // 내 볼륨 음소거
  const muteMe = () => {
    localAudioTrack?.setMuted(true);
    setIsMuted(true);
  };

  // 내 볼륨 음소거 해제
  const unmuteMe = () => {
    localAudioTrack?.setMuted(false);
    setIsMuted(false);
  };

  // 내 볼륨 크기 조절
  const handleMyVolume = (level: number) => {
    localAudioTrack?.setVolume(level);
  };

  // 특정 유저의 볼륨 크기 조절
  const handleRemoteVolume = (uid: number, level: number) => {
    const specificRemoteAudioTrack = remoteAudioTracks.filter((track) => {
      return track.getUserId() === uid;
    });

    if (specificRemoteAudioTrack.length === 0) return;

    specificRemoteAudioTrack.forEach((track) => {
      track.setVolume(level);

      setUserVolumes((prev) => {
        return {
          ...prev,
          [uid]: level,
        };
      });
    });
  };

  // 전체 음소거
  const muteAll = () => {
    setIsAllMuted(true);
  };

  // 전체 음소거 해제
  const unmuteAll = () => {
    setIsAllMuted(false);
  };

  return {
    agoraContextData,
    isMuted,
    muteMe,
    unmuteMe,
    isAllMuted,
    muteAll,
    unmuteAll,
    handleMyVolume,
    handleRemoteVolume,
  };
}
