import { useEffect } from 'react';

export default function Slider({
  ...props
}: React.InputHTMLAttributes<HTMLInputElement>) {
  useEffect(() => {
    const rangeInput = document.querySelector('input[type="range"]');

    const handleInputChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const percent =
        ((Number(target.value) - Number(target.min)) /
          (Number(target.max) - Number(target.min))) *
        100;
      target.style.backgroundImage = `linear-gradient(to right, #c869ff ${percent}%, #e0e0e0 ${percent}%)`;
    };

    if (rangeInput) {
      rangeInput.addEventListener('input', handleInputChange);

      handleInputChange({ target: rangeInput } as unknown as Event);
    }

    return () => {
      if (rangeInput) {
        rangeInput.removeEventListener('input', handleInputChange);
      }
    };
  }, []);

  return (
    <input
      type="range"
      min={0}
      max={100}
      defaultValue={50}
      style={{ width: '100%' }}
      {...props}
    />
  );
}
