import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { searchApi } from './core';
import { playApi, playApiWithoutToken } from './play';

export const useGetRoomsQuery = () => {
  return useInfiniteQuery({
    queryKey: ['getRooms'],
    queryFn: async ({ pageParam }) => {
      const { cursor, size, hasNext } = pageParam;

      if (!hasNext) return;

      const { data } =
        await playApiWithoutToken.getCursorPaginatedRoomResponseDtoList(
          cursor,
          size,
          ''
        );

      return data.data;
    },
    initialPageParam: {
      cursor: '',
      size: 30,
      hasNext: true,
    },
    getNextPageParam: (lastPage, allPage, lastPageParams) => {
      if (!lastPage?.hasNext)
        return {
          cursor: lastPage?.lastCursor || '',
          size: 30,
          hasNext: false,
        };

      return {
        cursor: lastPage?.lastCursor || '',
        size: 30,
        hasNext: lastPage?.hasNext,
      };
    },
  });
};

export const useGetRoomQuery = (roomId: string, enabled?: boolean) => {
  return useQuery({
    queryKey: ['getRoom', roomId],
    queryFn: async () => {
      if (!roomId) return null;

      const { data } = await playApi.getRoomResponseDto(roomId);

      return data;
    },
    enabled: enabled || true,
  });
};

export const useGetUserRoleQuery = (roomId: string) => {
  return useQuery({
    queryKey: ['getUserRole', roomId],
    queryFn: async () => {
      if (!roomId) return null;

      const { data } = await playApi.getRoomRoles(roomId);

      return data;
    },
    enabled: false,
  });
};

export const useGetCheckRoomsQuery = () => {
  return useQuery({
    queryKey: ['getCheckRooms'],
    queryFn: async () => {
      const { data } = await playApi.checkExistRoom();

      return data;
    },
  });
};

export const useGetProjectsQuery = ({
  keyword,
  enabled,
}: {
  keyword: string;
  enabled: boolean;
}) => {
  return useInfiniteQuery({
    queryKey: ['getProjects', keyword],
    queryFn: async ({ pageParam }) => {
      const { data } = await searchApi.searchProject(
        keyword,
        pageParam,
        30,
        '1.0'
      );

      return data.data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPage, lastPageParams) => {
      return lastPageParams + 1;
    },
    enabled,
  });
};

export const useGetRoomShareCode = (roomId: string) => {
  return useQuery({
    queryKey: ['getRoomShareCode', roomId],
    queryFn: async () => {
      const { data } = await playApi.getRoomShareCode(roomId);

      return data;
    },
  });
};
