import { APP_ENV } from '@src/config';
import axios from 'axios';

export const RWD_HOST = {
  production: 'rwd.to',
  development: 'test.rwd.to',
  local: 'test.rwd.to',
}[APP_ENV];

export const axiosInstance = axios.create({ baseURL: `https://${RWD_HOST}` });
