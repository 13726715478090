import React from 'react';
import { styled } from 'stitches.config';
import { BaseModal } from '../../BaseModal';

export type ConfirmFn<T> = (value?: T) => Promise<void>;

type PlayroomBaseModalProps = {
  title: string;
  content: React.ReactNode;
  okText: string;
  cancelText: string;
  onClose: () => void;
  onConfirm: ConfirmFn<string>;
};

export const PlayroomBaseModal = ({
  title,
  content,
  okText,
  cancelText,
  onClose,
  onConfirm,
}: PlayroomBaseModalProps) => {
  return (
    <BaseModal onClose={onClose} title={title}>
      {content}
      <ControllerContainer>
        <ControlButton
          onClick={onClose}
          style={{ backgroundColor: '#F2F2F2', color: '#515253' }}
        >
          {cancelText}
        </ControlButton>
        <ControlButton
          onClick={() => onConfirm()}
          style={{ backgroundColor: '#c869ff', color: '#fff' }}
        >
          {okText}
        </ControlButton>
      </ControllerContainer>
    </BaseModal>
  );
};

const ControllerContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '48px',
  marginTop: '24px',
});

const ControlButton = styled('button', {
  flex: 1,
  height: '100%',
  border: 'none',
  borderRadius: '999px',
  cursor: 'pointer',

  '&:first-of-type': {
    marginRight: '8px',
  },
});
