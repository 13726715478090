import {
  createInstance,
  HackleProvider as OriginalHackleProvider,
} from '@hackler/react-sdk';
import React from 'react';

type Env = 'production' | 'development';
export const APP_ENV: Env = (() => {
  if (
    process.env.GATSBY_APP_ENV === 'production' ||
    process.env.GATSBY_APP_ENV === 'development'
  )
    return process.env.GATSBY_APP_ENV;
  return 'development';
})();
export const isProduction = APP_ENV === 'production';
export const isDev = APP_ENV === 'development';

export const hackleKey = isProduction
  ? 'CLPnPYltSDcVGcVAsNrIOMMeK49vJIB4'
  : 'DaQ3SZK32pERirsvzbLY0yNyhlD7GkIk';

const hackleClient = createInstance(hackleKey, {
  debug: isDev,
  pollingIntervalMillis: 30000,
  auto_track_page_view: true,
});

interface HackleProviderProps {
  children?: React.ReactNode;
}

export function HackleProvider({ children }: HackleProviderProps) {
  return (
    <OriginalHackleProvider hackleClient={hackleClient} supportSSR>
      {children}
    </OriginalHackleProvider>
  );
}
