import ChevronDown from '@src/components/icons/ChevronDown';
import MicFilledIcon from '@src/components/icons/MicFilled';
import SheetHeader from '@src/components/Playroom/SheetHeader';
import { styled } from 'stitches.config';
import UserItem from '../../UserItem';
import LockIcon from '@assets/icons/lock-filled-large.svg';
import PeopleIcon from '@assets/icons/people.svg';
import NoticeIcon from '@assets/icons/notice-filled.svg';
import HeadsetIcon from '@assets/icons/headset-filled.svg';
import ChevronDownLargeIcon from '@assets/icons/chevron-down-large.svg';
import EmptyDagoo from '@assets/images/empty-list.png';
import LeaveIcon from '@assets/icons/leave-icon.svg';
import InviteIcon from '@assets/icons/invite-icon.svg';
import KebabIcon from '@assets/icons/kebab-icon.svg';
import SoundOffIcon from '@assets/icons/sound-off.svg';
import MicOffIcon from '@assets/icons/mic-off.svg';
import { usePlayroomContext } from '../..';
import { navigate } from 'gatsby';
import { useAgora } from '@src/hooks/useAgora';
import { roomStatusToLabel } from '@src/constants/playroom';
import Modal, { useModal } from '@src/components/Modal';
import { ConfirmLeaveDuringPlayModal } from '@src/components/Modal/play/ConfirmLeaveDuringPlayModal';
import { LoadingSpinner } from '@src/components/LoadingSpinner';
import {
  usePostExitRoomMutation,
  usePostModifyRoomStatusMutation,
} from '@src/apis/mutation';
import React from 'react';
import { useToastContext } from '@src/providers/Toast';

export default function MainSheet() {
  const { closeSheet, push, currentRoomData, isHost } = usePlayroomContext();
  const {
    agoraContextData: {
      agoraEngine,
      uid,
      channel,
      isReady,
      handleJoinOrLeave,
      remoteUsers,
    },
    isAllMuted,
    muteAll,
    unmuteAll,
    isMuted,
    muteMe,
    unmuteMe,
  } = useAgora();

  agoraEngine?.on('volume-indicator', (volumes) => {
    volumes.forEach((volume) => {
      const element = document.querySelector(`#user-id-${volume.uid}`);
      if (!element) return;

      if (volume.level > 50) {
        if (element instanceof HTMLDivElement) {
          element.style.boxShadow = `0 0 0 8px rgba(200, 105, 255, 0.2)`;
        }
      } else {
        if (element instanceof HTMLDivElement) {
          element.style.boxShadow = 'none';
        }
      }
    });
  });

  const { openModal, closeModal, modal } = useModal();
  const { showToast } = useToastContext();

  const { mutateAsync: exitRoom } = usePostExitRoomMutation();
  const { mutateAsync: changeRoomStatus } = usePostModifyRoomStatusMutation();
  const totalAgoraUid = [uid, ...remoteUsers.map((user) => user.uid)];

  const [isNoticeOpen, setIsNoticeOpen] = React.useState(false);
  const [isStatusChangeModalOpen, setIsStatusChangeModalOpen] =
    React.useState(false);

  const handleNoticeClick = () => {
    if (isHost) {
      if (currentRoomData?.description) {
        navigate(`/play/rooms/${currentRoomData?.id}/notice/modify`);
      } else {
        navigate(`/play/rooms/${currentRoomData?.id}/notice/create`);
      }

      closeSheet();
    } else {
      setIsNoticeOpen((prev) => !prev);
    }
  };

  const handleStatusBadgeClick = () => {
    if (isHost) {
      setIsStatusChangeModalOpen(true);
    }
  };

  const handleLeave = () => {
    if (!isReady && Boolean(channel)) return;

    if (currentRoomData?.status === 'Playing') {
      openModal(
        <ConfirmLeaveDuringPlayModal
          onClose={closeModal}
          onConfirm={async () => {
            await exitRoom();
            handleJoinOrLeave();

            closeSheet();
          }}
          isHost={isHost}
        />
      );

      return;
    }

    handleJoinOrLeave();

    closeSheet();
  };

  const handleStatusItemClick = async (status: 'Waiting' | 'Playing') => {
    const data = await changeRoomStatus({
      roomId: currentRoomData?.id || '',
      statusObject: {
        status,
      },
    });

    if (data?.success) {
      showToast(`모집 상태를 '${roomStatusToLabel[status]}'으로 변경했어요.`);

      setIsStatusChangeModalOpen(false);
    }
  };

  const handleProjectNameClick = () => {
    navigate(`/projects/${currentRoomData?.roomProject.id}`);

    closeSheet();
  };

  return (
    <>
      <SheetHeader
        title={currentRoomData?.title || ''}
        left={
          <Button onClick={handleLeave}>
            <img src={LeaveIcon} alt="방 나가기 아이콘" />
          </Button>
        }
        right={
          <div>
            <Button onClick={() => push('/playroom/invite')}>
              <img src={InviteIcon} alt="친구 초대 아이콘" />
            </Button>
            <Button
              onClick={() => {
                navigate(`/play/rooms/${currentRoomData?.id}/info`);

                closeSheet();
              }}
            >
              <img src={KebabIcon} alt="더보기 아이콘" />
            </Button>
          </div>
        }
      />
      <RoomInfo>
        <ImageWrapper>
          <ThumbnailImage
            src={currentRoomData?.roomProject.imageUrl}
            alt="프로젝트 썸네일"
          />
        </ImageWrapper>
        <div
          onClick={handleProjectNameClick}
          style={{ flexGrow: 1, flexShrink: 1, cursor: 'pointer' }}
        >
          <ProjectName>{currentRoomData?.roomProject.name}</ProjectName>
        </div>
        <StatusBadge
          status={currentRoomData?.status || 'Waiting'}
          onClick={handleStatusBadgeClick}
          isHost={isHost}
        >
          {roomStatusToLabel[currentRoomData?.status || 'Waiting']}
          {isHost && <ChevronDown />}
        </StatusBadge>
        {currentRoomData?.isPrivateRoom && (
          <div>
            <img
              src={LockIcon}
              alt="잠금 아이콘"
              style={{
                width: '20px',
                height: '20px',
                verticalAlign: 'sub',
              }}
            />
          </div>
        )}
        <div>
          <img
            src={PeopleIcon}
            alt="인원 아이콘"
            style={{
              width: '24px',
              height: '24px',
              marginRight: '4px',
            }}
          />
          <span style={{ verticalAlign: 'middle' }}>
            {currentRoomData?.currentUserCount}/{currentRoomData?.maxUserCount}
          </span>
        </div>
      </RoomInfo>
      <div
        style={{
          flexGrow: 1,
          flexShrink: 1,
          overflowY: 'auto',
        }}
      >
        {isHost ? (
          <NoticeContainer>
            <NoticeContent
              onClick={handleNoticeClick}
              style={{
                backgroundColor: currentRoomData?.description
                  ? '#f2f2f2'
                  : '#f2f9ff',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={NoticeIcon} alt="공지 아이콘" />
                <span style={{ marginLeft: '4px' }}>공지</span>
              </div>
              <div
                style={{
                  flexShrink: 1,
                  flexGrow: 1,
                  fontWeight: 400,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {currentRoomData?.description?.split('\n')[0] ||
                  '참여자들에게 전하고 싶은 말을 적어주세요!'}
              </div>
              <div>
                <ChevronDown />
              </div>
            </NoticeContent>
          </NoticeContainer>
        ) : (
          currentRoomData?.description && (
            <NoticeContainer>
              <NoticeContent
                onClick={handleNoticeClick}
                style={{
                  backgroundColor: '#f2f2f2',
                  flexWrap: isNoticeOpen ? 'wrap' : 'nowrap',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={NoticeIcon} alt="공지 아이콘" />
                  <span style={{ marginLeft: '4px' }}>공지</span>
                </div>
                {!isNoticeOpen && (
                  <div
                    style={{
                      flexShrink: 1,
                      flexGrow: 1,
                      fontWeight: 400,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {currentRoomData?.description.split('\n')[0]}
                  </div>
                )}
                <div>
                  <ChevronDown />
                </div>
                {isNoticeOpen && (
                  <div
                    style={{
                      width: '100%',
                      fontWeight: 400,
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {currentRoomData?.description}
                  </div>
                )}
              </NoticeContent>
            </NoticeContainer>
          )
        )}
        <MainContainer>
          {currentRoomData?.currentUserCount === 1 ? (
            <EmptyContainer>
              <img
                src={EmptyDagoo}
                alt="빈 목록 이미지"
                style={{ width: '160px', height: '140px' }}
              />

              <EmptyTitle>파티원 모집 중</EmptyTitle>
              <EmptySubTitle>
                플레이룸에 들어온 친구가 아직 없어요
              </EmptySubTitle>
            </EmptyContainer>
          ) : (
            <>
              {totalAgoraUid.map((currentUserAgoraId, index) => {
                const serverUserData = currentRoomData?.joinedUserList.find(
                  (user) => user.agoraUserId === currentUserAgoraId
                );

                if (serverUserData) {
                  return (
                    <UserItem
                      key={serverUserData.id}
                      name={serverUserData.name}
                      imageUrl={serverUserData.profileImageUrl || ''}
                      isHost={serverUserData.role === 'Master'}
                      isMe={serverUserData.agoraUserId === uid}
                      agoraUserId={serverUserData.agoraUserId}
                    />
                  );
                }
                return (
                  <LoadingItem key={index}>
                    <LoadingSpinner />
                  </LoadingItem>
                );
              })}
            </>
          )}
        </MainContainer>
      </div>
      <ControllerContainer>
        <ControllerItem onClick={isAllMuted ? unmuteAll : muteAll}>
          {isAllMuted ? (
            <img src={SoundOffIcon} alt="전체 음소거 아이콘" />
          ) : (
            <img src={HeadsetIcon} alt="전체 음소거 아이콘" />
          )}
        </ControllerItem>
        <ControllerItem onClick={isMuted ? unmuteMe : muteMe}>
          {isMuted ? (
            <img src={MicOffIcon} alt="음소거 아이콘" />
          ) : (
            <MicFilledIcon />
          )}
        </ControllerItem>
        <ControllerItem onClick={closeSheet}>
          <img src={ChevronDownLargeIcon} alt="최소화 아이콘" />
        </ControllerItem>
      </ControllerContainer>
      {/* 방 상태 변경 모달 */}
      {isStatusChangeModalOpen && (
        <StatusChangeModalBackground
          onClick={() => setIsStatusChangeModalOpen(false)}
        >
          <StatusChangeModalContainer>
            <StatusChangeModalContent onClick={(e) => e.stopPropagation()}>
              <StatusChangeModalTitle>모집 상태 설정</StatusChangeModalTitle>
              <StatusItem
                onClick={() => handleStatusItemClick('Waiting')}
                selected={currentRoomData?.status === 'Waiting'}
              >
                대기중
              </StatusItem>
              <StatusItem
                onClick={() => handleStatusItemClick('Playing')}
                selected={currentRoomData?.status === 'Playing'}
              >
                진행중
              </StatusItem>
            </StatusChangeModalContent>
            <ModalCancelButton>닫기</ModalCancelButton>
          </StatusChangeModalContainer>
        </StatusChangeModalBackground>
      )}
      <Modal {...modal} />
    </>
  );
}

const Button = styled('button', {
  all: 'unset',
  cursor: 'pointer',
});

const RoomInfo = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '8px',
  padding: '8px 16px',
});

const ProjectName = styled('h2', {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
});

const StatusBadge = styled('span', {
  display: 'flex',
  alignItems: 'center',
  padding: '3px 6px',
  fontSize: '12px',
  fontWeight: 700,
  lineHeight: '14px',
  borderRadius: '4px',

  variants: {
    status: {
      Waiting: {
        backgroundColor: '#F9F0FF',
        color: '#c869ff',
      },
      Playing: {
        backgroundColor: '#F2F2F2',
        color: '#8F9092',
      },
      Created: { backgroundColor: '#F2F2F2', color: '#8F9092' },
      Closed: { backgroundColor: '#F2F2F2', color: '#8F9092' },
    },
    isHost: {
      true: {
        cursor: 'pointer',
      },
      false: {
        cursor: 'default',
      },
    },
  },
});

const NoticeContainer = styled('div', {
  padding: '8px 16px',
});

const NoticeContent = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '8px',
  padding: '12px',
  borderRadius: '8px',
  backgroundColor: '#F2F9FF',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '20px',
  cursor: 'pointer',
});

const ControllerContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '8px 43.5px',
  borderTop: '1px solid #EBECED',
});

const ControllerItem = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '48px',
  height: '48px',
  borderRadius: '50%',
  backgroundColor: '#F2F2F2',
  cursor: 'pointer',
});

const MainContainer = styled('div', {
  width: '100%',
  height: '100%',
  overflowY: 'auto',
});

const EmptyContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

const EmptyTitle = styled('h2', {
  marginBottom: '8px',
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '22px',
});

const EmptySubTitle = styled('div', {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  color: '#a7a8a9',
});

const ImageWrapper = styled('div', {
  position: 'relative',
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  overflow: 'hidden',
});

const ThumbnailImage = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const LoadingItem = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px 16px',
});

// 방 상태 설정을 위한 모달. 컴포넌트로 빼야하나?
const StatusChangeModalBackground = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
});

const StatusChangeModalContainer = styled('div', {
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
  padding: '8px',
  paddingBottom: '16px',
});

const StatusChangeModalContent = styled('div', {
  position: 'relative',
  width: '100%',
  backgroundColor: '#fff',
  borderRadius: '8px',
});

const StatusChangeModalTitle = styled('div', {
  padding: '14px 16px',
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  color: '#a7a8a9',
});

const StatusItem = styled('div', {
  padding: '14px 16px',
  borderTop: '1px solid #F2F2F2',
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  color: '#D6D6D8',
  cursor: 'pointer',

  variants: {
    selected: {
      true: {
        color: '#D6D6D8',
      },
      false: {
        color: '#000',
      },
    },
  },
});

const ModalCancelButton = styled('button', {
  width: '100%',
  padding: '13px 16px',
  marginTop: '8px',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '22px',
  color: '#a7a8a9',
  backgroundColor: '#fff',
  outline: 'none',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
});
