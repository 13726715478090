import React from 'react';

import { CSSTransition } from 'react-transition-group';
import { styled } from 'stitches.config';

export type ModalProps = React.PropsWithChildren<{ open: boolean }>;

export default function Modal({ open, children }: ModalProps) {
  const nodeRef = React.useRef<HTMLDivElement>(null);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={open}
      timeout={duration}
      classNames="modal"
    >
      <ModalWarp aria-modal={open} ref={nodeRef}>
        {children}
      </ModalWarp>
    </CSSTransition>
  );
}

const duration = 200;

const ModalWarp = styled('div', {
  zIndex: 1000,
  '&.modal-enter': {
    opacity: 0,
  },
  '&.modal-enter-active': {
    opacity: 1,
    transition: `opacity ${duration}ms`,
  },
  '&.modal-exit': {
    opacity: 1,
  },
  '&.modal-exit-active': {
    opacity: 0,
    transition: `opacity ${duration}ms`,
  },
  '&.modal-exit-done': {
    display: 'none',
  },
});

export function useModal() {
  const [open, setOpen] = React.useState(false);
  const [children, setChildren] = React.useState<JSX.Element>();

  const openModal = React.useCallback((modal: JSX.Element) => {
    setOpen(true);
    setChildren(modal);
  }, []);

  const closeModal = React.useCallback(() => {
    setOpen(false);
  }, []);

  return { openModal, closeModal, modal: { open, children } };
}
