import React from 'react';
import { styled } from 'stitches.config';
import Sheet from 'react-modal-sheet';
import { route, useSheetRouter } from './router';
import { useAgora } from '@src/hooks/useAgora';
import { useGetRoomQuery } from '@src/apis/queries';
import { RoomDetailResponseDto } from '@uniquegood/realworld-play-interface/dist';

type UserItemProps = {
  name: string;
  imageUrl: string;
  isHost: boolean;
  isMe: boolean;
  userId?: string;
  agoraUserId?: number;
};

const PlayroomContext = React.createContext<{
  currentRoomData?: RoomDetailResponseDto;
  currentUserData?: UserItemProps;
  openSheet: () => void;
  closeSheet: () => void;
  push: (path: string) => void;
  pop: () => void;
  isHost: boolean;
  setCurrentUserData: (userData: UserItemProps) => void;
}>({
  currentRoomData: undefined,
  currentUserData: undefined,
  openSheet: () => {
    // pass
  },
  closeSheet: () => {
    // pass
  },
  push: (path: string) => {
    // pass
  },
  pop: () => {
    // pass
  },
  setCurrentUserData: (userData: UserItemProps) => {
    // pass
  },
  isHost: false,
});

export const usePlayroomContext = () => {
  const playroomContext = React.useContext(PlayroomContext);

  if (!playroomContext) {
    throw new Error('provider error');
  }

  return playroomContext;
};

export default function PlayroomProvider({
  children,
}: React.PropsWithChildren) {
  const { currentPath, push, pop } = useSheetRouter();
  const {
    agoraContextData: { channel, isConnected, uid },
  } = useAgora();
  const { data: roomData } = useGetRoomQuery(channel || '', true);

  const isHost =
    roomData?.data?.joinedUserList.find((user) => user.role === 'Master')
      ?.agoraUserId === uid;

  const [isOpen, setIsOpen] = React.useState(false);
  const [currentUserData, setCurrentUserData] = React.useState<
    UserItemProps | undefined
  >();

  const values = React.useMemo(
    () => ({
      currentRoomData: roomData?.data || undefined,
      currentUserData, // 추가된 부분
      setCurrentUserData,
      openSheet: () => {
        setIsOpen(true);
      },
      closeSheet: () => {
        setIsOpen(false);
      },
      push,
      pop,
      isHost,
    }),
    [setIsOpen, push, pop, roomData?.data, isHost, currentUserData]
  );

  React.useEffect(() => {
    if (window.Kakao) {
      window.Kakao.init('2dc0e6edbb838fbbdc7b2bcd12446837');
    }
  }, []);

  return (
    <PlayroomContext.Provider value={values}>
      {children}
      <PlayroomContainer>
        <CustomSheet
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          snapPoints={[0.65]}
        >
          <Sheet.Container>
            <Sheet.Header>
              <CustomDragIndicatorContainer>
                <CustomDragIndicator />
              </CustomDragIndicatorContainer>
            </Sheet.Header>
            <Sheet.Content>{route[currentPath]}</Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onTap={() => setIsOpen(false)} />
        </CustomSheet>
      </PlayroomContainer>
      {isConnected && (
        <FloatingButton onClick={() => setIsOpen(true)}>
          <FloatingButtonBackground
            src={roomData?.data?.roomProject.imageUrl}
          />
          <FloatingButtonContent>
            <CurrentProjectImage
              src={roomData?.data?.roomProject.imageUrl}
              alt="현재 프로젝트 이미지"
            />
          </FloatingButtonContent>
        </FloatingButton>
      )}
    </PlayroomContext.Provider>
  );
}

const PlayroomContainer = styled('div', {
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
});

const CustomSheet = styled(Sheet, {
  margin: '0 auto',
  maxWidth: '500px',

  '.react-modal-sheet-backdrop': {
    /* custom styles */
  },
  '.react-modal-sheet-container': {
    /* custom styles */
  },
  '.react-modal-sheet-header': {
    /* custom styles */
  },
  '.react-modal-sheet-drag-indicator': {
    /* custom styles */
  },
  '.react-modal-sheet-content': {
    /* custom styles */
    flexShrink: 1,
  },
});

const CustomDragIndicatorContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '20px',
});

const CustomDragIndicator = styled('div', {
  width: '32px',
  height: '4px',
  borderRadius: '2px',
  backgroundColor: '#EBECED',
});

const FloatingButton = styled('button', {
  position: 'fixed',
  right: '24px',
  bottom: '24px',
  width: '72px',
  height: '72px',
  padding: '12px',
  borderRadius: '16px',
  opacity: 1,
  cursor: 'pointer',
  backgroundColor: 'transparent',
  border: 'none',
  overflow: 'hidden',
  boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.2)',
  zIndex: '999',
});

const FloatingButtonBackground = styled('img', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  opacity: 0.6,
  filter: 'blur(4px)',
});

const FloatingButtonContent = styled('div', {
  position: 'relative',
  width: '48px',
  height: '48px',
  borderRadius: '50%',
  overflow: 'hidden',
});

const CurrentProjectImage = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});
