import SheetHeader from '@src/components/Playroom/SheetHeader';
import LeftIcon from '@assets/icons/leading-icn.svg';
import DefaultProfileImage from '@assets/profile/user-default-img.svg';
import InfoIcon from '@assets/icons/info-mark.svg';
import SpeakerMinIcon from '@assets/icons/speaker-min-filled.svg';
import SpeakerOnIcon from '@assets/icons/speaker-on-filled.svg';
import { styled } from 'stitches.config';
import { usePlayroomContext } from '../..';
import Slider from '@src/components/Slider';
import { useAgora } from '@src/hooks/useAgora';

export default function SettingSheet() {
  const { currentRoomData, currentUserData, pop } = usePlayroomContext();
  const {
    agoraContextData: { userVolumes },
    handleRemoteVolume,
  } = useAgora();

  const handleVolumeChange = (value: number) => {
    if (!currentUserData?.agoraUserId) return;

    handleRemoteVolume(currentUserData.agoraUserId, value);
  };

  return (
    <>
      <SheetHeader
        title={currentRoomData?.title}
        left={
          <Button onClick={pop}>
            <img src={LeftIcon} alt="뒤로 가기 아이콘" />
          </Button>
        }
      />
      <UserContainer>
        <ImageWrapper>
          <img
            src={
              currentUserData?.imageUrl
                ? currentUserData.imageUrl
                : DefaultProfileImage
            }
            alt="유저 이미지"
            style={{ width: '100%', height: '100%' }}
          />
        </ImageWrapper>
        <Username>{currentUserData?.name}</Username>
      </UserContainer>
      <VolumeControlContainer>
        <div style={{ marginBottom: '8px' }}>
          <span style={{ marginRight: '4px' }}>음량 조절</span>
          <img src={InfoIcon} alt="정보 아이콘" />
        </div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <img src={SpeakerMinIcon} alt="최소 음량 아이콘" />
          <Slider
            defaultValue={userVolumes[currentUserData?.agoraUserId || ''] || 50}
            onChange={(e) => handleVolumeChange(Number(e.target.value))}
            style={{
              flexGrow: 1,
              border: 'border: 0px solid #000101;',
            }}
          />
          <img src={SpeakerOnIcon} alt="최대 음량 아이콘" />
        </div>
      </VolumeControlContainer>
      {/* TODO: 차단/신고 기능 추가 시 구현 */}
      {/* <MenuContainer>
        <MenuItem>차단하기</MenuItem>
        <MenuItem style={{ color: '#F54E54' }}>신고하기</MenuItem>
      </MenuContainer> */}
    </>
  );
}

const Button = styled('button', {
  all: 'unset',
  cursor: 'pointer',
});

const UserContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px 40px',
});

const ImageWrapper = styled('div', {
  width: '64px',
  height: '64px',
  marginBottom: '16px',
  borderRadius: '50%',
  overflow: 'hidden',
});

const Username = styled('div', {
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '22px',
});

const VolumeControlContainer = styled('div', {
  padding: '0 16px',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
});

const MenuContainer = styled('div', {
  marginTop: '16px',
});

const MenuItem = styled('div', {
  padding: '18px 16px',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  color: '#191919',
  cursor: 'pointer',
});
