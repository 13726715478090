type Env = 'production' | 'development';
export const API_ENV: Env = (() => {
  if (
    process.env.GATSBY_APP_ENV === 'production' ||
    process.env.GATSBY_APP_ENV === 'development'
  )
    return process.env.GATSBY_APP_ENV;
  return 'development';
})();

export const LOGIN_PAGE_HOST = {
  production: 'ui.realworld.to',
  development: 'api-test.realworld.to',
}[API_ENV];

export const INTEGRATE_LOGIN_HOST = {
  production: 'account.realworld.to',
  development: 'account-test.realworld.to',
}[API_ENV];

export const CLIENT_ID = 'GiftCard';
export const CLIENT_SECRET = {
  production:
    'o44BN1mOj+wL5RWIpTHWu46NncSohlQOXrdFzE8NXSqmUs06pbS/rCj+7KqTV5GA',
  development:
    'o44BN1mOj+wL5RWIpTHWu46NncSohlQOXrdFzE8NXSqmUs06pbS/rCj+7KqTV5GA',
}[API_ENV];
