import { ApiErrorResponseModel } from '@uniquegood/realworld-core-interface/dist';
import {
  ApiFailResponseDto,
  ApiSuccessResponseDtoUnit,
} from '@uniquegood/realworld-play-interface/dist';
import axios from 'axios';
import { axiosInstance } from '..';

/*
 * Swagger Generator로 생성된 코드를 사용하지 않는 이유
 * 코드젠으로 생성된 호출 메서드의 경우 request body에 undefined나 null이 들어가면 자동으로 빈 객체 {}로 변환되어 전송됨.
 * 방 입장 시 비밀번호의 경우 비밀번호가 있으면 request body를 보내고 없으면 보내지 않아야 하는데, 코드젠으로 생성된 호출 메서드를 사용하면 이를 구현할 수 없음.
 * 따라서 직접 axios를 사용하여 호출 메서드를 구현함.
 */
export async function postEnterRoom({
  roomId,
  password,
  shareCode,
}: {
  roomId: string;
  password?: string;
  shareCode?: string;
}) {
  const data = await axiosInstance.post(
    `/api/play/rooms/${roomId}/enter`,
    password || shareCode
      ? {
          password,
          shareCode,
        }
      : undefined
  );

  return data;
}
