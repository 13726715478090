import SheetHeader from '@src/components/Playroom/SheetHeader';
import LeftIcon from '@assets/icons/leading-icn.svg';
import FacebookIcon from '@assets/icons/share/facebook_icon.svg';
import KakaoIcon from '@assets/icons/share/kakao_icon.svg';
import TwitterIcon from '@assets/icons/share/twitter_icon.svg';
import ShareElseIcon from '@assets/icons/share/share_else.svg';
import { usePlayroomContext } from '../..';
import { styled } from 'stitches.config';
import { createPermalink } from '@src/apis/permalink/createPermalink';
import React from 'react';
import { useGetRoomShareCode } from '@src/apis/queries';

export default function InviteSheet() {
  const { pop, currentRoomData } = usePlayroomContext();

  const { data: shareCodeData } = useGetRoomShareCode(
    currentRoomData?.id || ''
  );

  const utm = {
    facebook: `utm_source=meta&utm_campaign=web&utm_medium=playroom_share&utm_content=${currentRoomData?.id}`,
    kakao: `utm_source=kakao&utm_campaign=web&utm_medium=playroom_share&utm_content=${currentRoomData?.id}`,
    twitter: `utm_source=twitter&utm_campaign=web&utm_medium=playroom_share&utm_content=${currentRoomData?.id}`,
    etc: `utm_source=link&utm_campaign=web&utm_medium=playroom_share&utm_content=${currentRoomData?.id}`,
  };

  const handleFacebookClick = async () => {
    try {
      const open = window.open(
        'about:blank',
        '',
        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=600,height=400'
      );

      const url = `${window.location.origin}/play/rooms/${currentRoomData?.id}/invite/${shareCodeData?.data?.shareCode}?${utm.facebook}`;

      const permalink = await handleCreatePermalink(url);

      if (open) {
        open.location.replace(
          `https://www.facebook.com/sharer/sharer.php?u=${permalink}`
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleKakaoClick = async () => {
    try {
      const url = `${window.location.origin}/play/rooms/${currentRoomData?.id}/invite/${shareCodeData?.data?.shareCode}?${utm.kakao}`;

      const permalink = await handleCreatePermalink(url);

      const user =
        JSON.parse(localStorage.getItem('userData') || '{}').name || '';

      window.Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: `[${currentRoomData?.roomProject.name}] 플레이룸에 입장해주세요!`,
          description: `${user}님이 기다리고 있어요. 즐거운 플레이하시길 바랍니다!`,
          imageUrl: currentRoomData?.roomProject.imageUrl || '',
          link: {
            webUrl: permalink,
            mobileWebUrl: permalink,
          },
        },
        buttons: [
          {
            title: '플레이룸 바로가기',
            link: {
              webUrl: permalink,
              mobileWebUrl: permalink,
            },
          },
        ],
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleXClick = async () => {
    try {
      const open = window.open(
        'about:blank',
        'TwitterWindow',
        'width=600,height=450'
      );

      const url = `${window.location.origin}/play/rooms/${currentRoomData?.id}/invite/${shareCodeData?.data?.shareCode}?${utm.twitter}`;

      const permalink = await handleCreatePermalink(url);

      const user =
        JSON.parse(localStorage.getItem('userData') || '{}').name || '';

      const xUrl = `https://twitter.com/intent/tweet?&text=[${encodeURIComponent(
        currentRoomData?.roomProject.name || ''
      )}] 플레이룸에 입장해주세요! ${encodeURIComponent(
        user
      )}님이 기다리고 있어요. 즐거운 플레이하시길 바랍니다! ${encodeURIComponent(
        permalink
      )}`;

      if (open) {
        open.location.replace(xUrl);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleEtcClick = async () => {
    try {
      // 추후 공유하기 기능 추가될 수도 있습니다.
      // const permalink = await handleCreatePermalink(url);

      // const user =
      //   JSON.parse(localStorage.getItem('userData') || '{}').name || '';

      // await window.navigator.share({
      //   title: `[${currentRoomData?.roomProject.name}] 플레이룸에 입장해주세요! ${user}님이 기다리고 있어요. 즐거운 플레이하시길 바랍니다! - 지금 바로 리얼월드에서 플레이 해보세요!`,
      //   text: `[${currentRoomData?.roomProject.name}] 플레이룸에 입장해주세요! ${user}님이 기다리고 있어요. 즐거운 플레이하시길 바랍니다! - 지금 바로 리얼월드에서 플레이 해보세요!`,
      //   url: permalink,
      // });

      const url = `${window.location.origin}/play/rooms/${currentRoomData?.id}/invite/${shareCodeData?.data?.shareCode}?${utm.etc}`;

      await navigator.clipboard.writeText(url);
      alert('클립보드에 공유 URL을 복사했습니다.');
    } catch (e) {
      console.error(e);
    }
  };

  const handleCreatePermalink = async (url: string) => {
    try {
      const data = await createPermalink(url);

      return data?.url || '';
    } catch (e) {
      throw new Error('Create Permalink Failed');
    }
  };

  React.useEffect(() => {
    if (window.Kakao && !window.Kakao.isInitialized()) {
      window.Kakao.init('2dc0e6edbb838fbbdc7b2bcd12446837');
    }
  }, []);

  return (
    <>
      <SheetHeader
        title={currentRoomData?.title}
        left={
          <Button onClick={pop}>
            <img src={LeftIcon} alt="뒤로 가기 아이콘" />
          </Button>
        }
      />
      <SocialContainer>
        <SocialButton onClick={handleFacebookClick}>
          <SocialImage src={FacebookIcon} alt="페이스북 아이콘" />
          <SocialName>페이스북</SocialName>
        </SocialButton>
        <SocialButton onClick={handleKakaoClick}>
          <SocialImage src={KakaoIcon} alt="카카오톡 아이콘" />
          <SocialName>카카오톡</SocialName>
        </SocialButton>
        <SocialButton onClick={handleXClick}>
          <SocialImage src={TwitterIcon} alt="트위터(X) 아이콘" />
          <SocialName>X(트위터)</SocialName>
        </SocialButton>
        <SocialButton onClick={handleEtcClick}>
          <SocialImage src={ShareElseIcon} alt="기타 아이콘" />
          <SocialName>URL 복사</SocialName>
        </SocialButton>
      </SocialContainer>
    </>
  );
}

const Button = styled('button', {
  all: 'unset',
  cursor: 'pointer',
});

const SocialContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
});

const SocialButton = styled('button', {
  all: 'unset',
  cursor: 'pointer',

  '&:not(:last-child)': {
    marginRight: '24px',
  },
});

const SocialImage = styled('img', {
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  overflow: 'hidden',
});

const SocialName = styled('div', {
  marginTop: '8px',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  textAlign: 'center',
});
