import { axiosInstance } from '..';

export const createPermalink = async (url: string, note?: string) => {
  try {
    const { data } = await axiosInstance.post<{
      url: string;
      resource: string | null;
    }>('/api/permalinks/common', {
      url,
      note,
    });

    return data;
  } catch (e) {
    console.error(e);
  }
};
