import React from 'react';
import InviteSheet from './sheets/Invite';
import MainSheet from './sheets/Main';
import SettingSheet from './sheets/Setting';

export const route: Record<string, React.ReactElement> = {
  '/playroom': <MainSheet />,
  '/playroom/settings': <SettingSheet />,
  '/playroom/invite': <InviteSheet />,
};

export const useSheetRouter = () => {
  const [history, setHistory] = React.useState<string[]>(['/playroom']);
  const currentPath = history[history.length - 1];

  const push = React.useCallback((path: string) => {
    setHistory((prev) => [...prev, path]);
  }, []);

  const pop = React.useCallback(() => {
    setHistory((prev) => prev.slice(0, prev.length - 1));
  }, []);

  return { currentPath, push, pop };
};
