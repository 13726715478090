export default function MicFilledIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="8" y="3" width="9" height="13" rx="4.5" fill="currentColor" />
      <path d="M12.5 18L12.5 21" stroke="white" strokeWidth="1.5" />
      <path
        d="M10 21H15"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6 11V12V12C6 15.3137 8.68629 18 12 18H13C16.3137 18 19 15.3137 19 12V11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
