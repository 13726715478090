import { useCoreApiWithAuth } from '@src/apis/core/hooks';
import { track } from '@src/track';
import { getLocalStorage } from '@src/utils/localStorage';
import { decodeJwt } from 'jose';
import React from 'react';

interface TrackProviderProps {
  children: JSX.Element;
}

export default function TrackProvider({ children }: TrackProviderProps) {
  const { getMe } = useCoreApiWithAuth();
  React.useEffect(() => {
    const accessToken = getLocalStorage('accessToken');

    if (!accessToken) {
      track.initialize({});
    } else {
      const { sub } = decodeJwt(accessToken);

      (async () => {
        const { credentials } = await getMe();

        track.initialize({
          userId: sub,
          loginPlatform: credentials[0].provider,
        });
      })();
    }
  }, []);

  return children;
}
