import React from 'react';
import CloseIcon from '@assets/icons/clear_icon.svg';
import { keyframes, styled } from '@stitches/react';

interface BaseModalProps {
  onClose: () => unknown;
  title: string;
  children: React.ReactNode;
  divider?: boolean;
}

export function BaseModal({
  onClose,
  title,
  children,
  divider,
}: BaseModalProps) {
  return (
    <BackgroundContainer onClick={onClose}>
      <Container onClick={(e) => e.stopPropagation()}>
        <TitleContainer>
          <TitleText>{title}</TitleText>
          <CloseBtn src={CloseIcon} onClick={onClose} />
        </TitleContainer>
        {divider ? <Divider /> : <div style={{ height: '16px' }}></div>}
        <Main>{children}</Main>
      </Container>
    </BackgroundContainer>
  );
}

const scaleUp = keyframes({
  '0%': {
    transform: 'scale(0.7)',
  },
  '45%': {
    transform: 'scale(1.05)',
  },
  '80%': {
    transform: 'scale(0.95)',
  },
  '100%': {
    transform: 'scale(1)',
  },
});

const BackgroundContainer = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: 10000,
});

const Container = styled('div', {
  position: 'relative',
  width: 'calc(100% - 42px)',
  maxWidth: '500px',
  backgroundColor: '$white',
  padding: '16px 24px',
  border: '1px solid #ededed',
  borderRadius: '10px',
  animation: `0.3s ease 0s 1 normal none running ${scaleUp}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const TitleContainer = styled('div', {
  width: '100%',
  height: '24px',
  position: 'relative',
});

const Main = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
});

const CloseBtn = styled('img', {
  position: 'absolute',
  top: 0,
  right: 0,
  width: '24px',
  height: '24px',
  cursor: 'pointer',
});

const TitleText = styled('div', {
  position: 'relative',
  fontSize: '20px',
  lineHeight: '24px',
  textAlign: 'center',
});

const Divider = styled('div', {
  marginTop: '16px',
  marginBottom: '24px',
  backgroundColor: '#ededed',
  height: '2px',
  width: '100%',
});
