import { getLocalStorage, setLocalStorage } from '@src/utils/localStorage';
import {
  AuthApi,
  Configuration as AuthInterfaceConfiguration,
} from '@uniquegood/realworld-auth-interface';
import axios from 'axios';
import { decodeJwt } from 'jose';
import { API_ENV, CLIENT_ID, LOGIN_PAGE_HOST } from '..';
import { memoizedPostRefreshAccessToken } from '../core/postRefreshAccessToken';

export const AUTH_SERVER_HOST = {
  production: 'auth.realworld.to', // FIXME: 아닐수도 있음
  development: 'auth-test.realworld.to',
}[API_ENV];

const configuration = new AuthInterfaceConfiguration({
  basePath: `https://${AUTH_SERVER_HOST}`,
});

const axiosInstance = axios.create({ withCredentials: true });
const axiosInstanceWithToken = axios.create({ withCredentials: true });

axiosInstanceWithToken.interceptors.request.use(async (config) => {
  try {
    if (!config.headers) return config;

    let token = getLocalStorage('accessToken');
    if (!token) throw new Error('No token');

    const { exp } = decodeJwt(token);
    if (!exp) throw new Error('No exp');

    const now = Math.floor(Date.now() / 1000);
    if (exp < now) {
      const refreshToken = getLocalStorage('refreshToken');
      if (!refreshToken) throw new Error('No refreshToken');

      const refreshed = await memoizedPostRefreshAccessToken({
        accessToken: token,
        refreshToken,
      });
      setLocalStorage('accessToken', refreshed.token);
      setLocalStorage('refreshToken', refreshed.refreshToken);
      token = refreshed.token;
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      // SSO 세션이 있을 수 있으므로 한번 더 시도
      const {
        data: { token: newAccessToken, refreshToken: newRefreshToken },
      } = await authApi.apiAuthReissueGet();

      if (newAccessToken && newRefreshToken) {
        setLocalStorage('accessToken', newAccessToken);
        setLocalStorage('refreshToken', newRefreshToken);
      } else {
        throw new Error('No token or refreshToken');
      }

      config.headers.Authorization = `Bearer ${newAccessToken}`;
    }
  } catch (e) {
    alert('로그인이 해제되어 로그인 페이지로 이동합니다.');

    window.location.href = `https://${LOGIN_PAGE_HOST}/ui/auth/oauth?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${encodeURI(
      window.location.href
    )}`;
  }

  return config;
});

export const authApiWithToken = new AuthApi(
  configuration,
  undefined,
  axiosInstanceWithToken
);
export const authApi = new AuthApi(configuration, undefined, axiosInstance);
