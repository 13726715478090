import axios from 'axios';
import { API_ENV } from '..';
import {
  Configuration,
  ProjectApi,
  SearchApi,
} from '@uniquegood/realworld-core-interface';

export const CORE_SERVER_HOST = {
  production: 'api.realworld.to',
  development: 'api-test.realworld.to',
}[API_ENV];

export const coreApi = axios.create({
  baseURL: `https://${CORE_SERVER_HOST}`,
});

const configuration = new Configuration({
  basePath: `https://${CORE_SERVER_HOST}`,
});

export const projectApi = new ProjectApi(configuration, undefined, coreApi);
export const searchApi = new SearchApi(configuration, undefined, coreApi);
