import smoothscroll from 'smoothscroll-polyfill';
import 'swiper/css';

import './src/styles/global.css';
import './src/styles/swiper.css';

import { HackleProvider } from './src/providers/Hackle';
import TrackProvider from './src/providers/Track';
import PlayroomProvider from './src/providers/Playroom';
import AgoraProvider from './src/providers/Agora';
import ReactQuery from './src/providers/ReactQuery';
import ToastProvider from './src/providers/Toast';
import React from 'react';

smoothscroll.polyfill();
window.addEventListener('languagechange', () => location.reload());

export const wrapRootElement = ({ element }) => {
  return (
    <HackleProvider>
      <TrackProvider>
        <ToastProvider>
          <ReactQuery>
            <AgoraProvider>
              <PlayroomProvider>{element}</PlayroomProvider>
            </AgoraProvider>
          </ReactQuery>
        </ToastProvider>
      </TrackProvider>
    </HackleProvider>
  );
};
