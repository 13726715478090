import { createStitches } from '@stitches/react';

export const { styled, css, getCssText } = createStitches({
  theme: {
    fonts: {
      system: 'system-ui',
      NotoSansKR: 'Noto Sans KR',
    },
    colors: {
      fontLight: 'rgba(255, 255, 255, 0.5)',
      fontDark: '#1e112c',
      backgroundLight: '#fff',
      backgroundDark: 'rgba(0, 0, 0, .4)',
      backgroundDarkBlue: '#0a111e',
      black: '#000000',
      white: '#ffffff',
      spinnerGray: '#d8d8d8',
    },
    fontSizes: {
      1: '13px',
      2: '15px',
      3: '17px',
    },
    space: {
      projectDetailMobilePageContainerDefaultPadding: '16px',
    },
  },
  media: {
    bpMax420: '(max-width: 420px)',
    bpMax575: '(max-width: 575px)',
    bpMax680: '(max-width: 680px)',
    bpMax767: '(max-width: 767px)',
    bpMax991: '(max-width: 991px)',
    bpMax1199: '(max-width: 1199px)',
    bpMin1200: '(min-width: 1200px)',
    bpMin992: '(min-width: 992px)',
    bpMin768: '(min-width: 768px)',
    bpMin568: '(min-width: 568px)',
  },
});
