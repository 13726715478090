import { styled } from 'stitches.config';
import { PlayroomBaseModal } from '../PlayroomBaseModal';

type ConfirmLeaveDuringPlayModalProps = {
  onClose: () => void;
  onConfirm: () => void;
  isHost: boolean;
};

export const ConfirmLeaveDuringPlayModal = ({
  onClose,
  onConfirm,
  isHost,
}: ConfirmLeaveDuringPlayModalProps) => {
  const handleClose = () => {
    onClose();
  };

  const handleConfirm = async () => {
    onConfirm();
  };

  return (
    <PlayroomBaseModal
      title="플레이룸을 나가실 건가요?"
      onClose={handleClose}
      onConfirm={handleConfirm}
      content={
        isHost ? (
          <Content>
            지금 플레이룸을 나가면
            <br />
            방장 권한이 다음 플레이어에게 위임되며
            <br />
            게임 진행에 영향이 갈 수 있어요.
            <br />
            그래도 나가시겠어요?
          </Content>
        ) : (
          <Content>
            게임 플레이 도중에 나가게 되면 <br />
            다른 분들에게 지장이 갈 수 있어요.
            <br />
            <br />
            나가기 전, 다른 분들과 충분히 협의해 주세요!
          </Content>
        )
      }
      okText="나가기"
      cancelText="취소"
    ></PlayroomBaseModal>
  );
};

const Content = styled('div', {
  color: '#515253',
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
});
