import {
  usePostExitRoomMutation,
  usePostTokenMutation,
} from '@src/apis/mutation';
import { playApi } from '@src/apis/play';
import { getAgoraToken } from '@src/apis/play/getAgoraToken';
import { useGetRoomQuery, useGetUserRoleQuery } from '@src/apis/queries';
import { AGORA_APP_ID } from '@src/config';
import AgoraRTC, {
  AgoraRTCProvider,
  useClientEvent,
  useJoin,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteUsers,
  useRemoteAudioTracks,
  RemoteAudioTrack,
  ILocalAudioTrack,
  useRTCClient,
  IRemoteAudioTrack,
  IAgoraRTCRemoteUser,
  AgoraRTCReactError,
  useCurrentUID,
  UID,
  IAgoraRTCClient,
} from 'agora-rtc-react';
import React from 'react';

const AgoraControlContext = React.createContext<{
  agoraEngine: IAgoraRTCClient | undefined;
  uid?: UID;
  channel?: string;
  setChannel: React.Dispatch<React.SetStateAction<string | undefined>>;
  isReady: boolean;
  isConnected: boolean;
  isMuted: boolean;
  isAllMuted: boolean;
  setIsMuted: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAllMuted: React.Dispatch<React.SetStateAction<boolean>>;
  userVolumes: Record<string, number>;
  setUserVolumes: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  joinError: AgoraRTCReactError | null;
  handleJoinOrLeave: (channel?: string) => void;
  localAudioTrack: ILocalAudioTrack | null;
  remoteAudioTracks: IRemoteAudioTrack[];
  remoteUsers: IAgoraRTCRemoteUser[];
}>({
  agoraEngine: undefined,
  uid: undefined,
  channel: undefined,
  setChannel: () => {
    // pass
  },
  isReady: false,
  isConnected: false,
  isMuted: false,
  isAllMuted: false,
  setIsMuted: () => {
    // pass
  },
  setIsAllMuted: () => {
    // pass
  },
  userVolumes: {},
  setUserVolumes: () => {
    // pass
  },
  joinError: null,
  handleJoinOrLeave: () => {
    // pass
  },
  localAudioTrack: null,
  remoteAudioTracks: [],
  remoteUsers: [],
});

export const useAgoraControlContext = () => {
  const agoraControlContext = React.useContext(AgoraControlContext);

  if (!agoraControlContext) {
    throw new Error('provider error');
  }

  return agoraControlContext;
};

export default function AgoraProvider({ children }: React.PropsWithChildren) {
  const agoraEngine = AgoraRTC.createClient({
    codec: 'vp8',
    mode: 'rtc',
  });

  if (typeof window === 'undefined') return <>children</>;

  return (
    <AgoraRTCProvider client={agoraEngine}>
      <AgoraInnerProvider>{children}</AgoraInnerProvider>
    </AgoraRTCProvider>
  );
}

function AgoraInnerProvider({ children }: React.PropsWithChildren) {
  const agoraEngine = useRTCClient();

  const [isReady, setIsReady] = React.useState(false);
  const [channel, setChannel] = React.useState<string>();
  const [isMuted, setIsMuted] = React.useState(false);
  const [isAllMuted, setIsAllMuted] = React.useState(false);
  const [userVolumes, setUserVolumes] = React.useState<Record<string, number>>(
    {}
  );

  const setIntervalIdRef = React.useRef<ReturnType<typeof setInterval> | null>(
    null
  );
  const agoraUsersRef = React.useRef<IAgoraRTCRemoteUser[]>([]);
  const myUidRef = React.useRef<number>();

  const { mutateAsync: postToken } = usePostTokenMutation();
  const { mutateAsync: exitRoom } = usePostExitRoomMutation();
  const { data: roomData, refetch: refetchRoom } = useGetRoomQuery(
    channel || ''
  );
  const { data: users, refetch } = useGetUserRoleQuery(channel || '');

  const uid = useCurrentUID();
  const { localMicrophoneTrack } = useLocalMicrophoneTrack();
  const remoteUsers = useRemoteUsers();
  const { audioTracks: remoteAudioTracks } = useRemoteAudioTracks(remoteUsers);

  // TODO: setParameter 메서드가 있는데 아직 문서에는 나와있지 않습니다. AgoraRTC의 타입에도 추가되어 있지 않은데, 추후에 추가되면 @ts-ignore 삭제 부탁드립니다.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  AgoraRTC.setParameter('AUDIO_VOLUME_INDICATION_INTERVAL', 200);

  const syncRoom = () => {
    if (setIntervalIdRef.current) {
      clearTimeout(setIntervalIdRef.current);
      setIntervalIdRef.current = null;
    }
    setIntervalIdRef.current = setInterval(async () => {
      try {
        const serverUsers = (await playApi.getRoomRoles(channel || '')).data
          .data;
        const serverUsersExceptMe = serverUsers?.filter(
          (user) => user.agoraUserId !== uid
        );
        const agoraUsers = agoraUsersRef.current;

        const hasMe = serverUsers?.find(
          (user) => user.agoraUserId === myUidRef.current
        )
          ? true
          : false;

        if (!hasMe) {
          await exitRoom();
          setIsReady(false);
          setChannel(undefined);

          setIsMuted(false);
          setIsAllMuted(false);

          setIntervalIdRef.current && clearInterval(setIntervalIdRef.current);
          setIntervalIdRef.current = null;
        }

        await refetch();

        if (serverUsersExceptMe?.length === agoraUsers.length) {
          if (setIntervalIdRef.current) {
            await refetchRoom();

            clearInterval(setIntervalIdRef.current);
            setIntervalIdRef.current = null;
          }
        }
      } catch (e) {
        await exitRoom();

        setIsReady(false);
        setChannel(undefined);
      }
    }, 2000);
  };

  // 아고라 이벤트 핸들러
  useClientEvent(agoraEngine, 'user-joined', () => {
    syncRoom();
  });

  useClientEvent(agoraEngine, 'user-left', (user, reason) => {
    setUserVolumes((prev) => {
      const { [user.uid]: _, ...rest } = prev;

      return rest;
    });

    syncRoom();
  });

  useClientEvent(agoraEngine, 'user-published', (user, mediaType) => {
    console.log(`The user ${user.uid} has published a ${mediaType} stream.`);
  });

  useClientEvent(
    agoraEngine,
    'connection-state-change',
    (data1, data2, data3) => {
      console.log('connection-state-change', data1, data2, data3);

      if (data1 === 'DISCONNECTED') {
        setIsReady(false);
        setChannel(undefined);

        exitRoom();
      }
    }
  );

  useClientEvent(agoraEngine, 'token-privilege-will-expire', async () => {
    try {
      if (!channel) return;

      const { token, agoraUserId } = (await getAgoraToken(channel)) || {};

      await agoraEngine.renewToken(token || '');
    } catch (e) {
      console.error(e);
    }
  });

  // 내 음성 publish
  usePublish([localMicrophoneTrack]);

  const { data, isConnected, isLoading, error } = useJoin(async () => {
    agoraEngine.enableAudioVolumeIndicator();

    const { token, agoraUserId } = (await postToken()) || {};

    return {
      appid: AGORA_APP_ID,
      channel: channel || '',
      token: token || null,
      // token:
      //   '007eJxTYPh8Q25rxCK9XRvau0M7v3DnXj8y/cX2w+oJ5s8cjfNuCXgqMKRamCamGKcmJRkmWZgkJVlamJimmZgYp5mbW5oampqa/3bYlVogzsBw51nabEYGRgYWIAbxmcAkM5hkAZOMDFpcDIYGxgamRmamZmYANWgjxg==',
      uid: agoraUserId,
    };
  }, isReady && Boolean(channel));

  const values = React.useMemo(
    () => ({
      agoraEngine,
      uid,
      channel,
      setChannel,
      isReady,
      joinError: error,
      isConnected,
      isMuted,
      isAllMuted,
      setIsMuted,
      setIsAllMuted,
      userVolumes,
      setUserVolumes,
      handleJoinOrLeave: (channel?: string) => {
        if (!channel) {
          setChannel(undefined);
          setIsReady(false);

          if (setIntervalIdRef.current) {
            clearInterval(setIntervalIdRef.current);
            setIntervalIdRef.current = null;
          }
        } else {
          setChannel(channel);
          setIsReady(true);
        }
      },
      localAudioTrack: localMicrophoneTrack,
      remoteAudioTracks,
      remoteUsers,
    }),
    [
      agoraEngine,
      uid,
      channel,
      setChannel,
      isReady,
      error,
      isConnected,
      isMuted,
      isAllMuted,
      setIsMuted,
      setIsAllMuted,
      userVolumes,
      setUserVolumes,
      localMicrophoneTrack,
      remoteAudioTracks,
      remoteUsers,
    ]
  );

  React.useEffect(() => {
    return () => {
      exitRoom();
      localMicrophoneTrack?.close();
    };
  }, [agoraEngine]);

  React.useEffect(() => {
    agoraUsersRef.current = remoteUsers;
  }, [remoteUsers]);

  React.useEffect(() => {
    myUidRef.current = uid as number;
  }, [uid]);

  React.useEffect(() => {
    if (!isConnected) {
      setIsMuted(false);
      setIsAllMuted(false);
    }
  }, [isConnected]);

  if (typeof window === 'undefined') return <>{children}</>;

  return (
    <AgoraControlContext.Provider value={values}>
      {children}
      {remoteAudioTracks.map((track) => (
        <RemoteAudioTrack
          volume={50}
          key={track.getTrackId()}
          track={track}
          play={!isAllMuted}
        />
      ))}
    </AgoraControlContext.Provider>
  );
}
