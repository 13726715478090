import { styled } from 'stitches.config';

interface SheetHeaderProps {
  left?: React.ReactNode;
  title?: string;
  right?: React.ReactNode;
}

export default function SheetHeader({ title, left, right }: SheetHeaderProps) {
  return (
    <Container>
      {left}
      <Title>{title}</Title>
      {right}
    </Container>
  );
}

const Container = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '56px',
  padding: '8px',
});

const Title = styled('div', {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});
