import { styled } from '@stitches/react';
import React from 'react';
import ReactDOM from 'react-dom';

export const ToastContext = React.createContext<{
  message: string;
  showToast: (message: string) => void;
}>({
  message: '',
  showToast: () => {
    // pass
  },
});

export const useToastContext = () => React.useContext(ToastContext);

export default function ToastProvider({ children }: React.PropsWithChildren) {
  const [toast, setToast] = React.useState('');

  const toastTimeoutRef = React.useRef<
    ReturnType<typeof setTimeout> | undefined
  >(undefined);

  const showToast = React.useCallback((message: string) => {
    if (toastTimeoutRef.current) {
      toastTimeoutRef.current = undefined;

      clearTimeout(toastTimeoutRef.current);
    }
    setToast(message);

    toastTimeoutRef.current = setTimeout(() => {
      setToast('');
    }, 3000);
  }, []);

  const values = React.useMemo(
    () => ({
      message: toast,
      showToast,
    }),
    [toast, showToast]
  );

  return (
    <ToastContext.Provider value={values}>
      {children}
      {ReactDOM.createPortal(
        <ToastContainer>
          <ToastItem isOpen={Boolean(toast)}>{toast}</ToastItem>
        </ToastContainer>,
        document.body
      )}
    </ToastContext.Provider>
  );
}

const ToastContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  textAlign: 'center',
  backgroundColor: 'transparent',
  pointerEvents: 'none',
  zIndex: 99999999,
});

const ToastItem = styled('div', {
  display: 'inline-block',
  position: 'absolute',
  bottom: '30px',
  padding: '14px 16px',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  backgroundColor: '#353536',
  color: '#fff',
  borderRadius: '4px',
  transition: 'transform 0.3s, visibility 0.3s, opacity 0.3s',

  variants: {
    isOpen: {
      true: {
        transform: 'translateY(-120px)',
        visibility: 'visible',
        opacity: 1,
      },
      false: {
        transform: 'translateY(-48px)',
        visibility: 'hidden',
        opacity: 0,
      },
    },
  },
});
