import { AgoraTokenResponseDto } from '@uniquegood/realworld-play-interface/dist';
import axios from 'axios';
import { axiosInstance } from '..';

export const getAgoraToken = async (
  channel?: string
): Promise<AgoraTokenResponseDto | undefined> => {
  try {
    const { data } = await axiosInstance.post(
      '/api/play/agora/token',
      channel ? { roomId: channel } : undefined
    );

    return data.data as AgoraTokenResponseDto;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw new Error(e.response?.data.message);
    }
  }
};
