import moize from 'moize';
import { assert, string, type } from 'superstruct';
import { coreApi } from '..';

export interface PostRefreshAccessTokenProps {
  accessToken: string;
  refreshToken: string;
}

const postRefreshAccessTokenResponse = type({
  refreshToken: string(),
  token: string(),
});

interface RefreshResponseModel {
  token: string;
  refreshToken: string;
}

export async function postRefreshAccessToken(
  props: PostRefreshAccessTokenProps
) {
  const { data } = await coreApi.post<RefreshResponseModel>(
    `/api/auth/refresh`,
    props
  );

  assert(data, postRefreshAccessTokenResponse);

  return data;
}

export const memoizedPostRefreshAccessToken = moize(postRefreshAccessToken, {
  maxAge: 5000,
});
