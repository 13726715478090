import React from 'react';
import { keyframes } from '@stitches/react';
import { styled } from 'stitches.config';

export function LoadingSpinner() {
  return (
    <LoadingSpinnerWrap viewBox="0 0 50 50">
      <LoadingSpinnerCircle
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
      />
    </LoadingSpinnerWrap>
  );
}

const rotate = keyframes({
  '100%': {
    transform: 'rotate(360deg)',
  },
});
const dash = keyframes({
  '0%': {
    strokeDasharray: '1, 150',
    strokeDashoffset: '0',
  },
  '50%': {
    strokeDasharray: '90, 150',
    strokeDashoffset: '-35',
  },
  '100%': {
    strokeDasharray: '90, 150',
    strokeDashoffset: '-124',
  },
});

const LoadingSpinnerWrap = styled('svg', {
  animation: `${rotate} 2s linear infinite`,
  width: '50px',
  height: '50px',
});

const LoadingSpinnerCircle = styled('circle', {
  stroke: '$spinnerGray',
  strokeLinecap: 'round',
  animation: `${dash} 1.5s ease-in-out infinite`,
});
