type Env = 'production' | 'development' | 'local';
export const APP_ENV: Env = (() => {
  if (
    process.env.GATSBY_APP_ENV === 'production' ||
    process.env.GATSBY_APP_ENV === 'development'
  )
    return process.env.GATSBY_APP_ENV;
  return 'local';
})();

export const AGORA_APP_ID = {
  production: 'e85ad3ebb1b84bb9845f443f77951557',
  development: '867f155e923a4a8bb3598be580c5fc56',
  local: '867f155e923a4a8bb3598be580c5fc56',
}[APP_ENV];

export const WEB_PLAY_URL = {
  production: 'https://play.realworld.to',
  development: 'https://development.realworld-web-play.pages.dev',
  local: 'http://localhost:3000',
}[APP_ENV];

export const PLAYGROUND_URL = {
  production: 'https://playground.realworld.to',
  development: 'https://playground-test.realworld.to',
  local: 'http://localhost:3000',
}[APP_ENV];
