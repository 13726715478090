import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CreateRoomRequestDto,
  UpdateRoomRequestDto,
  UpdateRoomStatusRequestDto,
} from '@uniquegood/realworld-play-interface/dist';
import { playApi } from './play';
import { getAgoraToken } from './play/getAgoraToken';
import { postEnterRoom } from './play/postEnterRoom';

export const usePostTokenMutation = () => {
  return useMutation({
    mutationKey: ['postToken'],
    mutationFn: async () => {
      const data = await getAgoraToken();

      return data;
    },
  });
};

export const usePostCreateRoomMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['postCreateRoom'],
    mutationFn: async (values: CreateRoomRequestDto) => {
      const { data } = await playApi.createRoom(values);

      return data;
    },
    onSuccess: () => {
      // TODO: queryKey가 getRooms인 요청 만들기
      queryClient.invalidateQueries({ queryKey: ['getRooms'] });
    },
  });
};

export const usePostExitRoomMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['postExitRoom'],
    mutationFn: async () => {
      const { data } = await playApi.exitRoom();

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getRooms'] });
    },
  });
};

export const usePostJoinRoomMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['postJoinRoom'],
    mutationFn: async ({
      roomId,
      password,
      shareCode,
    }: {
      roomId: string;
      password?: string;
      shareCode?: string;
    }) => {
      // FIXME: codegen에서 생성된 호출 메서드에서 requestBody를 undefined로 넘기면 빈 객체 {}로 치환해서 요청하는 문제가 있음.
      // 일단 호출 함수 직접 구현하고, 추후 codegen(realworld-interface 레포지토리) 쪽 수정 필요할 것 같습니다.
      // const { data } = await playApi.enterRoom(roomId, { password });
      const data = await postEnterRoom({
        roomId,
        password,
        shareCode,
      });

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getRooms'] });
      queryClient.invalidateQueries({ queryKey: ['getRoom'] });
    },
  });
};

export const usePostModifyRoomMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['postModifyRoom'],
    mutationFn: async ({
      roomId,
      infoObject,
    }: {
      roomId: string;
      infoObject: UpdateRoomRequestDto;
    }) => {
      const { data } = await playApi.updateRoom(roomId, infoObject);

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getRooms'] });
      queryClient.invalidateQueries({ queryKey: ['getRoom'] });
    },
  });
};

export const usePostModifyRoomStatusMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['modifyRoomStatus'],
    mutationFn: async ({
      roomId,
      statusObject,
    }: {
      roomId: string;
      statusObject: UpdateRoomStatusRequestDto;
    }) => {
      if (!roomId) return;

      const { data } = await playApi.updateRoomStatus(roomId, statusObject);

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getRooms'] });
      queryClient.invalidateQueries({ queryKey: ['getRoom'] });
    },
  });
};
